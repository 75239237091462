<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="登录日志">
      <table-page :config="config3" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { loginStatus } from '@/utils/public';

export default {
  name: 'SaasAdminRebateRulesReviewDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '1',
      config3: {
        dataUrl: `/login/log/list`,
        filter: {
          controls: [
            {
              key: 'time',
              label: '登录时间',
              type: 'dateRange'
            },
            {
              key: 'loginStatus',
              label: '登陆状态',
              type: 'souSelect',
              width: '260',
              config: {
                options: loginStatus
              }
            }
          ]
        },
        columns: [
          {
            key: 'createdDate',
            title: '登录时间',
            width: '130px',
            type: 'date',
          },
          {
            key: 'city',
            title: '登录城市',
            width: '130px',
          },
          {
            key: 'ip',
            title: '登录IP',
            width: '130px',
          },
          {
            key: 'loginStatus',
            title: '登录状态',
            width: '130px',
            type: 'textEnum',
            config: {
              constants: {
                success: '登录成功',
                failed: '登录失败',
              }
            }
          },
          {
            key: 'username',
            title: '登陆账号',
            width: '130px',
          },
          {
            key: 'mark',
            title: '标记',
            width: '180px',
            ellipsis: true
          },
        ],
        /* 表格操作 */
        actions: [
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        ]
      },
    };
  },
  mounted() {
  },
  methods: {
  }

};
</script>

<style lang="less" scoped></style>